import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SemaphoreService {
  private locked: boolean = false;

  async acquire(): Promise<void> {
    while (this.locked) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.locked = true;
  }

  release(): void {
    this.locked = false;
  }
}