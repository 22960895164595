import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  constructor() { }

  public async get(key: string): Promise<string | null> {
    const { value } = await Preferences.get({ key: key });
    return value;
  }

  public async set(key: string, value: string) {
    await Preferences.set({
      key: key,
      value: value
    });
  }

  public async remove(key: string) {
    await Preferences.remove({ key: key });
  }
}
